<template>
  <div>
    <div class="card mb-3" style="width: 100%" v-loading="loading" :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Whatsapp Integration</h3>
      </div>
      <el-row :gutter="12">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="table-container mt-20 ml-3 mb-2">
          <el-radio-group v-model="selectedIntegration">
            <el-radio label="WA_CLOUD">WA - Cloud Api</el-radio>
            <el-radio label="WA_WEB">WA - Web</el-radio>
          </el-radio-group>

          <div v-if="selectedIntegration === 'WA_CLOUD'" class="integration-fields mt-1">
            <el-form :model="cloudApiForm" :rules="rules" style="width: 25vh" ref="cloudApiForm" label-position="top"
              label-width="120px">
              <el-form-item label="Account Name" prop="accountName">
                <el-input v-model="cloudApiForm.accountName" required class="input-in-whatsapp"></el-input>
              </el-form-item>
              <el-form-item label="Version" prop="version">
                <el-input v-model="cloudApiForm.version" class="input-in-whatsapp"></el-input>
              </el-form-item>
              <el-form-item label="Phone Number" prop="phoneNumber">
                <el-input v-model="cloudApiForm.phoneNumber" class="input-in-whatsapp"></el-input>
              </el-form-item>
              <el-form-item label="Access Token" prop="accessToken">
                <el-input v-model="cloudApiForm.accessToken" class="input-in-whatsapp"></el-input>
              </el-form-item>
              <el-form-item label="WA Business Id" prop="waBusinessId">
                <el-input v-model="cloudApiForm.waBusinessId" class="input-in-whatsapp"></el-input>
              </el-form-item>
            </el-form>
            <div>
              <el-button type="primary" @click="waIntegrate('CLOUD')" class="save-in-whatsapp">
                Save
              </el-button>
            </div>
          </div>

          <div v-if="selectedIntegration === 'WA_WEB'" class="integration-fields mt-1">
            <el-form :model="webForm" style="width:90%" ref="webForm">
              <el-form-item label="Account Name" prop="accountName">
                <el-input placeholder="Please input" v-model="webForm.accountName" class="input-with-select">
                  <template slot="prepend">{{ getWorkspaceName(getActiveWorkspace.company_id) }}</template>
                  <el-button slot="append" @click="initForQR"><i class="fa-solid fa-qrcode"></i></el-button>
                </el-input>
              </el-form-item>
              <div id="WA_QR"></div>
              <div v-if="QR.base64">
                <el-button type="primary" @click="waIntegrate('WEB')"  class="save-in-whatsapp">
                  Save
                </el-button>
              </div>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { createInstance, generateQR,connectionStatus } from "./whatsapp";
import { mapGetters } from "vuex";
import appConfig from '@/config/app';
import { postAPICall } from "@/helpers/httpHelper";
export default {
  data() {
    return {
      loading: false,
      loadingText:'Loading......',
      selectedIntegration: "WA_CLOUD",
      QR: {},
      instance:{},
      cloudApiForm: {
        accountName: "",
        version: "",
        phoneNumber: "",
        accessToken: "",
        waBusinessId: "",
      },
      webForm: {
        accountName: "",
        appKey: "",
        authKey: "",
        phoneNumber: "",
      },
      rules: {
        accountName: [
          {
            required: true,
            message: "Please enter AccountName",
            trigger: "blur",
          },
        ],
        version: [
          { required: true, message: "Please enter Version", trigger: "blur" },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        phoneNumber: [
          {
            required: true,
            message: "Please enter Your Phone Number",
            trigger: "blur",
          },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        accessToken: [
          {
            required: true,
            message: "Please enter Your Access Token",
            trigger: "blur",
          },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        waBusinessId: [
          {
            required: true,
            message: "Please enter your WA Business ID",
            trigger: "blur",
          },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["getActiveWorkspace", "getAuthenticatedUser", "getWorkspaceList"]),
    getWorkspaceName() {
      return (companyId) => {
        let workspaceData = this.getWorkspaceList.find((workspace) => {
          return workspace.company_id._id==companyId;
        });
        return workspaceData?.company_id?.name;
      }
    },
    checkWhitespace() {
      return (str) => {
      return /\s/.test(str);
      }
    }
  },
  methods: {
    IntegrationList() {
      const formRef =
        this.selectedIntegration === "WA_CLOUD"
          ? "cloudApiForm"
          : "webForm";
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          this.$router.push({ path: "/configuration/WhatsappList" });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async initForQR() {
      try {
        this.loading=true;
        this.instance={};
        this.loadingText = 'Validating the details .... Please wait a while';
        if(!this.webForm.accountName)
        {
          this.loading=false;
          this.$message.error({
            showClose: true,
            message: "Whatsapp Web account name is required",
            center: true,
          });
          return;
        }
        if(this.checkWhitespace(this.webForm.accountName))
        {
          this.loading=false;
          this.$message.error({
            showClose: true,
            message: "Invalid Account name Format.Don`t contains space and other special characters",
            center: true,
          });
          return;
        }
        this.loadingText="Saving your configuration..... Please wait ......";
        let instanceName= `${appConfig.WBA_ENV_CODE}_${this.getActiveWorkspace.company_id}_${this.webForm.accountName}`;
        this.instance = await createInstance({ instanceName: instanceName, description: 'Kodefast Application' });
        if (this.instance && this.instance.error) {
          this.loading=false;
          this.$message.error({
            showClose: true,
            message: this.instance?.message?.[0] || "Whatsapp Server Not connected",
            center: true,
          });
        }
        this.loadingText="Please wait generating QR....."
        if (this.instance && this.instance.Auth) {
          this.QR = await generateQR(this.instance.Auth.token, this.instance.name);
          const QR_Html = `<img src="${this.QR.base64}" alt="Whatsapp Web Integration " /> <br/><p style="color:red;">Note : Hey there! To send fewer messages, just scan the QR code with your WhatsApp mobile app. Don't forget to save after a successful connection in the WhatsApp application. Thanks!</p>`;
          document.getElementById("WA_QR").innerHTML = QR_Html;
          this.loading=false;
        }
      }
      catch (error) {
        // error.error
        this.$message.error({
          showClose: true,
          message: error.message,
          center: true,
        });
      }
    },
    async waIntegrate(type){
      try{
        this.loading=true;
        let data={}
        if(type==='CLOUD')
        {
            // Under Construction
        }
        else if(type ==='WEB')
        {
          this.loadingText= "Verifying connection status.... Please wait a while.."
          let status=await connectionStatus(this.instance.Auth.token,this.instance.name)
          console.log(status);
          if(status.state!='open')
          {
            this.loading=false;
            this.$message.error({
              showClose: true,
              message: 'You are currently not connected to WhatsApp Web. Connect now to access WhatsApp from your account and enjoy a seamless messaging experience.',
              center: true,
            });
            return;
          }
          data = {
            name:this.webForm.accountName,
            type:'WA_WEB',
            contactNo:this.webForm.accountName,
            instanceId:this.instance.name,
            key:null,
          }

        }

        let response= await postAPICall("POST","/wa/create",data);

        if(response && response.success)
        {
          this.$router.push({ path: "/configuration/AddNewIntegration" });
        }

      }
      catch(error)
      {
        console.log("WA Init",error)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.input-in-whatsapp {
  width: 70vh;
}
::v-deep .el-form-item {
  margin-top: 10px;
  margin-bottom: 0;
}
.save-in-whatsapp {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
  margin-left: 87%;
}
::v-deep .el-form-item__error {
  width: 230px;
}
::v-deep .el-form--label-top .el-form-item__label {
    margin-bottom: -21px;}
</style>
